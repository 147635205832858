import React from "react";
import css from "./_error.module.scss";
import { fireEvent } from "../utils/tracking";
import { isSourceIntegration } from "../utils/browser";

export default class Error extends React.Component {
  static getInitialProps({ res, err }) {
    const statusCode = res ? res.statusCode : err ? err.statusCode : null;
    return { statusCode };
  }

  fireReloadEvent() {
    fireEvent(
      "Error Card",
      "Reload Button Click",
      "Error Card Reload Button Click"
    );
  }

  render() {
    return (
      <div className={css["error-page"]}>
        <div className={css["inner-cont"]}>
          <img
            src={"/static/images/error-img.png"}
            alt="Glance Waiting"
            className={css["error-img"]}
          />
          <div className={css["error-subheading"]}>
            Looks like we got unlucky.
            <br />
            {!isSourceIntegration() && `Let’s try again.`}
          </div>
          {!isSourceIntegration() && (
            <a
              href="/"
              className={css["back-btn"]}
              onClick={this.fireReloadEvent}
            >
              Reload
            </a>
          )}
        </div>
      </div>
    );
  }
}
